import React from "react";
import { Link } from "react-router-dom";
function Main() {
  return (
    <main>
      <h2 className="section">Profile</h2>
      <section class="profile">
        <img src="/img/fujitarintaro.webp" alt="mainpic" />
        <h3>Rintaro FUJITA</h3>
        <div class="description">
          <p>
            From Tokyo, Japan. Currently a student at Kyoto Seika University. I
            have been involved in a wide range of activities, including web
            service development and electronic construction.
            <br />
            <br />
            I joined the Latent Media Lab in 2023, where I have been working
            with media art since my junior year, creating works that interact
            with existing products.
            <br />
            <br />I have exhibited my work at IPSJ INTERACTION, Maker Faire,
            INTERCOLLEGE SONIC ARTS FESTIVAL, and other external exhibitions and
            seminar exhibitions. I plan to actively participate in exhibitions
            both in Japan and abroad in the future.
          </p>
        </div>
        <br />
        <br />
        <div class="description">
          <p>
            東京都出身．京都精華大学在学中．活動はWebサービス開発や電子工作など
            多岐にわたる． <br />
            <br />
            2023年よりLatent Media Lab.に所属．3年時よりメディアアートに触れ，
            既存の製品に対して干渉していく制作を行っている．
            <br />
            <br />
            情報処理学会 INTERACTIONやMaker Faire，INTERCOLLEGE SONIC ARTS
            FESTIVALなどでの外部展示，ゼミ展示などこなし , 今後も国内外問わず ,
            展示会への積極的な参加を目論む .
          </p>
        </div>
      </section>
      <section>
        <h2 className="section">Skills</h2>
        <h3 className="skills">Programing Skills</h3>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>Docker</li>
          <li>Python3</li>
          <li>C++</li>
          <li>Swift</li>
          <li>Astro</li>
          <li>Svelte</li>
          <li>PHP</li>
          <li>MySQL</li>
          <li>WordPress</li>
          <br />
          <h3 className="skills2">Other Skills</h3>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>PremirePro</li>
          <li>Fusion360</li>
          <li>TouchDesigner</li>
        </ul>
      </section>
      <section>
        <h2 className="section">Web Works</h2>
        <ul>
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/"
              target="_blank"
              rel="noopener noreferrer"
             className="links">
              LatentMediaLab. - Web Create
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://librikyotoseika.github.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Libri - Web Create
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://rintarofujita.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              RintaroFujitaPortfolio - Web Create
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition1h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 1H "灯火" Event Site - Web Create
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 2H "Co-" Event Site - Web Create
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://caaccs.kyoto-seika.ac.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kyoto Seika University CAACCS Website - WordPress Cording
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://github.com/RintaroFujita"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub Page in Other Projects
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2 className="section">Art Works</h2>
        <ul>
          <li>
            <Link to="/bitshape">Bitshape</Link>
          </li>
          <br />
          <li>
            <Link to="/pulsatiolitoris">Pulsatio Litoris</Link>
          </li>
          <br />
          <li>
            <Link to="/decieve">Deceive</Link>
          </li>
          <br />
          <li>
            <Link to="/hackognition">Hackognition</Link>
          </li>
          <br />
          <li>
            <Link to="/recognitioncapturescene">Recognition: capture(scene)</Link>
          </li>
        </ul>
      </section>
      <section>
        <h2 className="section">Projects</h2>
        <ul>
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/ddnd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designing Danbaul & Dungeon - Cam System
            </a>
          </li>
          <br />
          <li>
            <Link to="technosomen">TechnoSOMEN - Hardware Create</Link>
          </li>
          <br />
          <li>
            <Link to="tongueprotecter">Tongue Protecter - Hardware Create</Link>
          </li>
          <br />
          <li>
            <Link to="technoandon">TechnoANDON - Hardware Create</Link>
          </li>
          <br />
        </ul>
      </section>
      <section>
        <h2 className="section">Conference / Exhibition </h2>
        <ul>
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition1h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 1H "灯火"
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://www.tokyoartbeat.com/events/-/Homeworks-2023-New-Unspoiled-Landscape/blend-studio/2023-11-25"
              target="_blank"
              rel="noopener noreferrer"
            >
              HOMEWORKS 2023『あたらしい原風景』
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://ic.jssa.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              INTERCOLLEGE SONIC ARTS FESTIVAL 2023
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://www.interaction-ipsj.org/proceedings/2024/data/bib/2B-38.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              IPSJ Interaction 2024
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 2H "Co-"
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://makezine.jp/event/makers-mfk2024/m0050/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Maker Faire Kyoto 2024
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://makezine.jp/event/makers-mft2024/m0079/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Maker Faire Tokyo 2024
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
              target="_blank"
              rel="noopener noreferrer"
            >
              SIGGRAPH Asia 2024 Art Gallery
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2 className="section">Awards</h2>
        <ul>
          <li>
            <a
              href="https://www.kyoto-seika.ac.jp/news/2024/0514_1.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Maker Faire Kyoto 2024 "TechnoANDON"- Jury Award
            </a>
          </li>
        </ul>
        <br />
        <br />
      </section>
      <section>
        <h2 className="section">Contact</h2>
        <ul>
          <li>Mail:</li>
          <li>rinpc356 (at) gmail.com</li>
          <br />
          <li>X:</li>
          <li>
            <a
              href="https://twitter.com/fritta625"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
          < br />
          <li>Instagram:</li>
          <li>
            <a
              href="https://www.instagram.com/r.fujita625/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
          < br />
          <li>GitHub:</li>
          <li>
            <a
              href="https://github.com/RintaroFujita"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
            </li>
        </ul>
      </section>
      <br />
      <br />
    </main>
  );
}

export default Main;
