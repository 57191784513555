import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/hackognize1.webp",
  },
  {
    id: 2,
    imageUrl: "img/hackognize2.webp",
  },
  //   {
  //     id: 3,
  //     imageUrl: "img/decieve3.webp",
  //   },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };


  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Hackognition</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          本作品は言葉と音の複雑な関係性に焦点を当て，音声認識システムの誤認識を利用したサウンド・インスタレーション作品です．
          本作品に配置されている音声認識アシスタントのSiriは空間に配置されたスピーカーから流れる音に反応します．
          音声認識システムはあくまで与えられた音を元に判断し，返答しますが，それらは作者が意図的に音声認識システムが誤認識するように選んだ音であるため，
          システムは読み間違えて食い違っていきます．
          作品の体験を通して，鑑賞者は本来の言葉の意味や関係性などを再考することになります．
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          Focusing on the complex relationship between words and sound, this
          work is a sound installation utilizing the misrecognition of a voice
          recognition system. Siri, the voice recognition assistant installed in
          this work, responds to sounds played from speakers placed in the
          space. The voice recognition system makes judgments and replies based
          on the given sounds, but because these are sounds that the author
          intentionally chose for the voice recognition system to misrecognize,
          the system misreads and misinterprets the sounds. The system misreads
          and responds differently. Through the experience of the work, the
          viewer is encouraged to reconsider the meaning of the original words
          and their relationship to each other.
        </p>
        <br />
        <br />
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/KZXdeZvYSzg?si=sZmzwwkv0KLD9-SJ"
          title="YouTube vid
        eo player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a
            href="https://scottallen.ws/biography"
            target="_blank"
            rel="noopener noreferrer"
          >
            Scott Allen(Advisor)
          </a>
        </p>
        <br />
        <section>
        <h2 className="section">Conference / Exhibition </h2>
        <ul>
          <li>
            <a
              href="https://www.tokyoartbeat.com/events/-/Homeworks-2023-New-Unspoiled-Landscape/blend-studio/2023-11-25"
              target="_blank"
              rel="noopener noreferrer"
            >
              HOMEWORKS 2023『あたらしい原風景』
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://ic.jssa.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              INTERCOLLEGE SONIC ARTS FESTIVAL 2023
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://www.interaction-ipsj.org/proceedings/2024/data/bib/2B-38.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              IPSJ Interaction 2024
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 2H "Co-"
            </a>
          </li>
          <br />
        </ul>
      </section>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
