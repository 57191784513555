import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/technoandon1.webp",
  },
  {
    id: 2,
    imageUrl: "img/technoandon2.webp",
  },
  {
    id: 3,
    imageUrl: "img/technoandon3.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={artwork.imageUrl}
              alt="Artwork"
              className="artworksimg-technoandon"
            />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">TechnoANDON</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          TechnoANDONは、行灯が従来の枠を超え、100台の行灯が音楽に合わせてリズミカルに同期、点灯し、その光が新たな物語を紡ぎます。
          古典的な日本の美と、電子工作の先端技術が交わり、参加者に感動と驚きをもたらします。歴史を背負いながらも、未来への道を照らすTechnoANDONで、
          新たなる一歩を踏み出しましょう。
          教育用のマイコン(Microbit)を用いて，ワークショップ等を行う際
          にはんだ付けが難しい，できない人たちでも簡単にネジ止め，ス
          ナップ止め，ゴムで止めすることで電子工作ができるように設計
          しています。
          <br />
          テクノシリーズ作品
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          In TechnoANDON, the lanterns go beyond the conventional framework,
          with 100 lanterns synchronized and lit rhythmically to music, and
          their lights weaving a new story. The classical beauty of Japan and
          the cutting-edge technology of electronics intermingle, bringing
          excitement and surprise to the participants. Take a new step forward
          with TechnoANDON, which lights the way to the future while bearing
          history on its back.The design is such that anyone can do electronic
          construction by screwing, snap-fastening, or rubber-fastening, even
          those who have difficulty or cannot solder when conducting workshops.
          <br />
          Techno Series Works
        </p>
        <br />
        <br />
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/wxpZdi311R0?si=wX4gYjiMpjHsVe6Q"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-med
        ia; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a href="https://www.instagram.com/yuki01_0w0/">
            Yuki Hirayama(Creator)
          </a>
          <br />
          <a href="https://x.com/hoehoe">hoehoe(Creator/Idea)</a>
          <br />
        </p>
        <br />
        <section>
          <h2 className="section">Awards</h2>
          <ul>
            <li>
              <a
                href="https://www.kyoto-seika.ac.jp/news/2024/0514_1.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Maker Faire Kyoto 2024 "TechnoANDON"- Jury Award
              </a>
            </li>
          </ul>
          <br />
          <br />
        </section>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
